













import { defineComponent, watchEffect, computed, ref } from "@vue/composition-api";
import Store from "../store/store";
import InputDatePicker from "./inputDatePicker.vue";


export default defineComponent({
  emits:['return-done-button','clear-button-pressed','return-click-away'],
  props: {
    buttonText : String,
    maxDate : String
  },
  components: {
    InputDatePicker
  },
  setup(props, {emit}) {
    const response = ref(Store.state.return);
    watchEffect(() => {
      Store.state.return = response.value;
    });

    const departureDate = computed(() => Store.state.departure.date); 

    const disabled = computed(() => !Store.state.departure.date);

    //MM Resets and Clears Return Journey
    function clearButtonPressed() {
      Store.resetReturnJourney();
      emit('clear-button-pressed');
    }

    function doneButtonPressed() {
      emit('return-done-button');
    }

    function clickAway() {
      emit('return-click-away');
    }
    return {
      Store,
      response,
      disabled,
      departureDate,
      clearButtonPressed,
      doneButtonPressed,
      clickAway,
    };
  },
});
