














































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import passengerIcon from '../assets/icons/passangersIcon.vue';
import plusIcon from '../assets/icons/newPlus.vue';
import minusIcon from '../assets/icons/newMinus.vue';
import Store from '../store/store';
import { mixin as clickaway } from 'vue-clickaway';
import { SSR, SsrType, TravelFare } from "../interface/types";

export default defineComponent({
  emits : ['update-passengers', 'update-bikeDropdown', 'update-wheelchairDropdown'],
  mixins: [ clickaway ],
  props: {
    options: Array,
  },
  components: {
    passengerIcon,
    plusIcon,
    minusIcon,
  },
  setup(props, {emit}) {

    const passengerData = computed(() => Store.state.passengers);
    const ssrSplits = computed(() => Store.state.ssrSplits);
    const open = ref(false);
    const updateKey = ref(0);
    const displayText = ref('Select Passengers');

    function increase(option) {
      if (!option.quantity) option.quantity = 0;
      option.quantity += 1;
      emit('update-passengers');
      Store.state.passengers = passengerData.value;
      displayText.value = '';
      passengerData.value?.forEach((element) => {
      if (element.quantity > 0) {
          displayText.value += ` ${element.name} x${element.quantity}`;
        }
      });
      populateBikeSSR(option);
      Store.state.wheelchairFareclass = 'NR';
      updateKey.value++;
    }

    function decrease(option) {
      if (!option.quantity) option.quantity = 0;
      if (option.quantity === 0) return;
      option.quantity -= 1;
      emit('update-passengers');
      Store.state.passengers = passengerData.value;
      displayText.value = '';
      passengerData.value?.forEach((element) => {
      if (element.quantity > 0) {
          displayText.value += ` ${element.name} x${element.quantity}`;
        }
      });
      if (displayText.value == ''){
        displayText.value = 'Select Passengers';
      }
      populateBikeSSR(option);
      Store.state.wheelchairFareclass = 'NR';
      updateKey.value++;
   }

    function away() {
      open.value = false;
    }

    function populateBikeSSR(option: TravelFare){
      //Update Bike SSR Input
      let passNumber = 0;

      let filteredArray = Store.state.ssrSplits;

      //iterate through passangers and fill bike inputs
      passengerData.value?.forEach(pass => {
        let bikeInputExists = false;

        if(filteredArray){
          filteredArray?.forEach(ssr => {
            if(ssr.ticketType == pass.fareClass){
              bikeInputExists = true;
            }
            if(ssr.ticketType == option.fareClass){
              //update the existing ssr quantityMax
              ssr.quantityMax = option.quantity;
            }
          })
        }else{
          filteredArray = [];
        }

        //pushes new entry to ssrSplit
        if(pass.quantity > 0 && !bikeInputExists){
          filteredArray?.push({
            description: pass.name + ' Bike',
            passanger: passNumber,
            ssrType: SsrType.BICYCLE,
            ticketType: pass.fareClass,
            quantity: 0,
            quantityMax: option.quantity
          })
          passNumber++;
        }else if(pass.quantity === 0){
          filteredArray = filteredArray?.filter(ssr => {
          return ssr.ticketType !== pass.fareClass;
          });

        }
        
      })

      Store.state.ssrSplits = filteredArray;
      emit('update-bikeDropdown');
      emit('update-wheelchairDropdown');
      ssrSplitsToSrrTotal();
    }

    function ssrSplitsToSrrTotal(){
      var storeSsr : Array<SSR> =  Array<SSR>();
      
      let bikeQuantity = 0;
      
      Store.state.ssrSplits?.forEach(ssr => {
        if(ssr.ssrType == SsrType.BICYCLE ){
          bikeQuantity = bikeQuantity + ssr.quantity
        }
      })

      if(bikeQuantity > 0){
        storeSsr.push({
          fareLineId: '',
          ssrType: "BICYCLE",
          ssrCount: bikeQuantity
        });
      }
      Store.state.ssrTotals = storeSsr;

    }

    return { 
      away,
      passengerData, 
      open, 
      Store, 
      updateKey, 
      increase, 
      decrease,
      displayText,
      ssrSplits
    };
  },
  methods: {
 
    openSearch() {
      this.open = !this.open;
    },

    

    //EK close if something else opened
    // onClickAway(event) {
    //   this.open=false;
    // }
  },
  
});
