



































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import WheelchairIcon from '../assets/icons/iconWheelchair.vue';
import { StopResponse } from "../interface/types";

//import plusIcon from '../icons/plus.vue';
import Store from '../store/store';

export default defineComponent({
  props: {
    disabled: Boolean,
    numOfPassTypes: Number},
  components: {
    WheelchairIcon
  },
  setup(props) {

    const passengerData = computed(() => Store.state.passengers);
    const open = ref(false);
    const selectedWheelchair = computed(() => Store.state.wheelchairFareclass);
    const updateKey = ref(0);

    const currentValue = ref(2);
    let numofOptions = props.numOfPassTypes;
    let singleSelectionCode = 'NR';
    const selected = ref(false);

    //EK - build a list of passengers as selected in the passenger box
    let wheelchairOptions = computed(() => {
      let selections: StopResponse[] = [];
      selections.push({code :'NR', name: 'Wheelchair not required', ssrs : []});
      let thisSelection: StopResponse;
      if (Store.state.passengers) {
        Store.state.passengers?.forEach(pass => {
            if (pass.quantity > 0) {
              thisSelection = {
                code: pass.fareClass,
                name: pass.name,
                ssrs: [],
              }
              selections.push(thisSelection);
            }
        })
      }
         numofOptions = selections.length - 1;
        if(selections.length == 2){
          singleSelectionCode = selections[1].code;
        }
      return selections;
    });

    const decisions = [
      {
        optionValue:"Yes", 
        id:1
      },
      {
        optionValue:"No", 
        id:2
      }
    ];
  
    const displayText = computed(() => {
      let dt = '';
      if (Store.state.wheelchairFareclass == 'NR') {
        dt = 'Wheelchair not requested';
      } else {
        if (Store.state.passengers) {
          Store.state.passengers?.forEach(pass => {
            if (pass.fareClass == Store.state.wheelchairFareclass) {
              dt = pass.name + ' Passenger Selected';
            } 
          });
        }
      }
      return dt;
    });

    function wheelchairChanged(id: any) {
      //wheelchair turned off
      if (id == 'NR') {
        Store.state.wheelchairRequired = false;
        Store.state.wheelchairFareclass = 'NR';
        selected.value = false;
      } else {
        if (Store.state.passengers) {
          Store.state.passengers?.forEach(pass => {
            if (pass.fareClass == id) {
              Store.state.wheelchairRequired = true;
              Store.state.wheelchairFareclass = id;
              selected.value = true;
            }
          });
        }
      }
      //open.value = false;
      updateKey.value ++;
    }

    function openSearch(id: any) {
      if (id == 1) {
        if(wheelchairOptions.value != null && numofOptions == 1){
          wheelchairChanged(singleSelectionCode);
        }else{
          open.value= true;
        }
      } else if(id == 2) {
        open.value= false;
      } else if(id == 3){
        open.value= true;

      } else if(id == 4){
        open.value= false;
        if(selected.value){
          currentValue.value = 2;
        }
        // turn button off if nothing selected
      if (Store.state.wheelchairFareclass == 'NR') {
        Store.state.wheelchairRequired = false;
        selected.value = false;
        updateKey.value ++;
      }

      }
 
    }


    //EK close if something else opened
    // function onClickAway(event: any) {
    //   open.value=false;
    // }
 

    return { 
        passengerData, 
        open, 
        Store, 
        wheelchairOptions,
        wheelchairChanged,
        openSearch,
        displayText,
        selectedWheelchair,
        updateKey,
        decisions,
        selected,
        currentValue
    };

  },

});

