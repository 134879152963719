export enum SearchType {
    Standard = 1,
    Explorer = 2,
    Multi = 3,
    RedeemExplorer = 4,
}
export interface SelectValue  {
    value: SearchType
    display: string
}

//Stops
export interface StopResponse {
    code: string,
    name: string,
    ssrs: string[],
}

export interface IBookingSearchTime {
    time: string,
    date: string,
}

export interface TravelTime {

    time: string,
    display: string,
}

export interface AdpositionsElements {
    Display: string,
    Value: AdpositionsEnum
}

export enum AdpositionsEnum {
    Before = 0,
    After = 1,
}

export interface BikeDropdown {
    description: string,
    passanger: number,
    quantity: number,
    quantityMax: number,
    ticketType: string,
    ssrType : number,
}

export interface IBookingSearch {
    origin: StopResponse,
    destination: StopResponse,
    productId: string,
    departure: IBookingSearchTime,
    return: IBookingSearchTime,
    fares?: Array<IFare>,
    ssr?: Array<SSR>,
    ssrListTypes?: SsrListType[]
    passengers?: TravelFare[]
    searchResponse?: SearchResponseModel
    loading: boolean
    loadingMessage: string,
    necModal: boolean
    wheelchairRequired:boolean,
    wheelchairFareclass: string,
    countries: CountryData[]
    error: boolean,
    errorReason: string,
    hasSearchError: boolean,
    
}

export interface IFare {
    id: string,
    count: number
}

export interface SSR {
    fareLineId: string,
    ssrType: string,
    ssrCount: number
}

export interface CountryData {
    countryId: string,
    description: string,
    diallingCode: string

}

export interface IProductSearch {

    origin: StopResponse,
    destination: StopResponse,
    start: IBookingSearchTime
}

export interface TravelFare {
    name: string,
    description: string,
    quantity: number
    order?: string;
    fareClass: string;
    cardNoRequired: boolean;
    //EK - price was not being stored - needs to be string for displaying decimal points properly
    ticketPrice: string;
}

export interface SearchResponseModel {

    outward: Journey[],
    return?: Journey[],
    //EK new error field
    error: string,
    messages? : ServiceMessage[],

}

export interface SearchRequestModel {
    originId: string,
    destinationId: string,
    journeyTypeId: string,
    departureDate: string,
    returnDate?: string,
    departureTime: string,
    returnTime?: string,
    fareIds: FareId[],
    ssr: SSR[],

}

export interface ServiceMessage {
    header : string,
    description : string,
}

export interface SearchResponseModel {

    outward: Journey[],
    return?: Journey[],
    //EK new error field
    error: string,
    messages? : ServiceMessage[],

}

//Search Response
export interface Journey {
    paxJourneyId: string,
    destDescription: string,
    originDescription: string,
    destStopCode: string,
    originStopCode: string,
    departDate: string,
    arriveDate: string,
    departTime: string,
    brand: string,
    brandDescription: string,
    arriveTime: string,
    price: number,
    ssrCapacity: boolean,
    duration: string,
    available: boolean
    fares: FareList[]
    //EK added changes array for display to the user
    journeys: IntermediateJourney[]
}

//EK - container for intermediate journey legs (changes)
export interface IntermediateJourney {
    journeyId: string;
    service: string;
    travelMode: string;
    brand: string;
    brandDescription: string;
    destDescription: string;
    originDescription: string;
    destStopCode: string;
    originStopCode: string;
    departDate: string;
    arriveDate: string;
    departTime: string;
    arriveTime: string;
    walkLinkInd: string;
}

export interface FareList {
    fareLineId: string;
    fareType: string;
    fareClass: string;
    fareCode: string;
    noOfPax: number;
    currency: string;
    priceWithIns: number;
    priceWithoutIns: number;
    fareClassDescription: string;
    error:string;
 }

export interface FareId {
    fareId: string,
    count: number   
}

//EK - new object for holding details on the header/footer bar that can be set
//individually by components e.g. switching on/off and changing text of buttons
export interface IVSystemSwitches {
    needsAdditionalStorage : boolean,
}

export interface IActiveAndHistoryTicket  {
    ticketNumber : string;
    transId : string;
    transactionDate : string;
    cancelled: boolean;
    ticketType: string;
    fareType :string;
    billingDetails : Customer;
    deliveryDetails : Customer;
    ticketClientType : string;
    origin : ITicketJourney;
    outwardDate : string;
    outwardTime : string;
    destination : ITicketJourney;
    returnDate : string;
    returnTime : string;
    noOfPax : number;
    totalCost : number;
    currency :string;
    promotionId : string;
    promotionDescription : string;
    ssr : ITicketSSR[];
    fares : ITicketFares[];
}
export interface Customer {
    title: string;
    firstName: string;
    lastName: string;
    addressLineOne: string;
    addressLineTwo: string;
    addressLineThree: string;
    town: string;
    postCode: string;
    telephone: string;
    county: string;
    country: string;
}

export interface IFavouriteJourneyModel {
    favouriteId : string;
    origin : IFavouriteJourney;
    destination : IFavouriteJourney;
}

 export interface IFavouriteProductModel {
    favouriteId : string;
    productId : string;
    description : string;
    currency : string;
    price : number;
 }
 
export interface IFavouriteJourney {
    stopGroupId : string;
    description : string;
    deleted : boolean;
}

export interface IExplorerPassDetails {
    voucherType : string,
    voucherCode : string,
    startDate : string,
    endDate : string,
    validityPeriod : number,
    error : string,

}

export interface ITicketJourney {
    stopGroupId : string;
    description : string;
    deleted : boolean;
}

export interface ITicketFares {
    fareClass : string;
    fareCode : string;
    noOfPax : number;
    cost : number;
}

export interface ITicketSSR {
    transId : string;
    transSeqNum : number;
    direction : string;
    ssrType : string;
    ssrCount : number;
}

export interface IBasket {
    activeFlow: SearchType,
    outbound?: Journey;
    return?: Journey;
    basketId: string,
    extras: Extra[]
    activeBasket?: BasketResponse
    price: number
    passangerInfo: PassangerInfo[]
    deliveryMethodId: string,
    deliveryPrice: number,
    deliveryAddress: Address,
    billingAddress: Address,
    summary?: CheckoutResponse
}

export interface Extra {
    passanger: number,
    quantity: number,
    ticketType: string,
    // EK - need ssr type as we now store wheelchairs
    ssrType : number,
}

export enum SsrType {
    Luggage,
    Wheelchair,
    BICYCLE,
}

export interface PassangerInfo {
    order: number,
    firstName: string,
    lastName: string,
    cardNo: string,
}

//EK - Updated basket response as D8 one didn't bring back proper tickets or SSR or products
export interface BasketResponse {
    basketId: string;
    timeout: string;
    total: number;
    //EK - use the 'proper' ticket
    tickets: FullTicket[];
    //tickets: Ticket[];
    vouchers: Voucher[];
    customer: Customer;
    passangers: Passanger[];
    products: CheckoutProduct[];
    ssr: IVSsr[];
    error: string;
}

export interface Address {
    title: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    address3: string;
    town: string;
    county: string;
    postcode: string;
    country: string;
    phone: string,
    email: string,
}

// EK - This is called CheckoutResponse but is actually the Basket response
// That's now being used pre-checkout as well.
export interface CheckoutResponse {
    transId: string;
    timeout: Date;
    basketTotal: number;
    tickets: CheckoutTicket[];
    basketItems: CheckoutBasketItems;
    deliveryOption? : DeliveryOption;
    error?:string;
}

export interface FullTicket {
    description: string,
    price : number,
    transSeqNum : number,
    outwardPaxJourneyId : string,
    returnPaxJourneyId : string,
    fareLineId : string,
}

export interface Voucher {
    voucherType: string;
    voucherCode: string;
    voucherAmount: number;
}

export interface Passanger {
    order: number;
    firstname: string;
    lastName: string;
    cardNo: string;
}

export interface CheckoutProduct {
    productId: string;
    currency: string;
    price: number;
    startDate: string;
    firstName: string;
    surname: string;
    photoCardNo: number;
    transSeqNum: number;
    productRef : string;
}

export interface CheckoutTicket {
    description: string;
    price: number;
    transSeqNum: number;
    outwardPaxJourneyId: string;
    returnPaxJourneyId: string;
    fareLineId: string;
    ticketNo: string;
}

export interface CheckoutProduct {
    productId: string;
    currency: string;
    price: number;
    startDate: string;
    firstName: string;
    surname: string;
    photoCardNo: number;
    transSeqNum: number;
    productRef : string;
}


export interface CheckoutBasketItems {
    reservations?: CheckoutReservation[];
    products?: CheckoutProduct[];
    currency: CheckoutCurrency;
    // eslint-disable-next-line
    ssr: any[];
    paxInfo: CheckoutPaxInfo;
    vouchers?: Voucher[];
}

export interface CheckoutOutward {
    paxJourneyId: string;
    destDescription: string;
    originDescription: string;
    destStopCode: string;
    originStopCode: string;
    departDate: string;
    arriveDate: string;
    departTime: string;
    arriveTime: string;
}

export interface CheckoutReturn {
    destDescription: string;
    originDescription: string;
    destStopCode: string;
    originStopCode: string;
    departDate: string;
    arriveDate: string;
    departTime: string;
    arriveTime: string;
}

export interface CheckoutSelectedJourney {
    outwardPaxJourneyId: string;
    returnPaxJourneyId: string;
}

export interface CheckoutSelectedFareLine {
    fareLineId: string;
    fareType: string;
    fareClass: string;
    fareCode: string;
    noOfPax: number;
    currency: string;
    priceWithIns: number;
    priceWithoutIns: number;
    fareClassDescription: string;
    noOfWheelchairs: number;
}

export interface CheckoutReservation {
    outwardJourney: CheckoutOutward;
    returnJourney: CheckoutReturn;
    selectedJourney: CheckoutSelectedJourney;
    selectedFareLines: CheckoutSelectedFareLine[];
}

export interface CheckoutPaxInfo {
    title: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    address3: string;
    town: string;
    county: string;
    postcode: string;
    country: string;
    daytimePhoneNumber: string;
    eveningPhoneNumber: string;
    // eslint-disable-next-line
    paxReferences: any[];
}

export interface CheckoutCurrency {
    ISO: string;
}

export interface IVSsr {
    transSeqNum: number;
    ssrType: string;
    quantity: number;
    price: number;
}

export interface DeliveryOption {
    deliveryMethodId: string;
    shortDescription: string;
    longDescription: string;
    leadTime: number;
    price: number;
    isValid: boolean;
}

export interface StopResponse {
    code: string,
    name: string,
}

export interface SsrListType {
    ssrTypeId: string;
    description: string;
    quantity: number;
}


