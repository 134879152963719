













import { defineComponent, watchEffect, ref } from "@vue/composition-api";
import Store from "../store/store";
import InputDatePicker from "./inputDatePicker.vue";


export default defineComponent({
  emits:['depart-done-button','depart-click-away'],
  props: {
    buttonText : String,
    minDate : String,
    maxDate : String,
  },
  methods: {
    doneButtonPressed() {
       this.$emit('depart-done-button');
    },
  },
  components: {
    InputDatePicker
  },
  setup() {
    const response = ref(Store.state.departure);

    const depDate = ref(null);

    watchEffect(() => {
        Store.state.departure = response.value; 
      });

    return {
      Store,
      response,
      depDate,
    };
  },
});
