import { AdpositionsElements, AdpositionsEnum, TravelTime } from "../interface/widgetTypes"

export default class TravelTimeData {

    public static adpositions: AdpositionsElements[] = [
        {
            Display: 'After',
            Value: AdpositionsEnum.After
        },
        {
            Display: 'Before',
            Value: AdpositionsEnum.Before
        }
    ]


    public static travelTimes: TravelTime[] = [
        {
            display: '01.00', time: '01:00:00'
        },
        {
            display: '02.00', time: '02:00:00'
        },
        {
            display: '03.00', time: '03:00:00'
        },
        {
            display: '04.00', time: '04:00:00'
        },
        {
            display: '05.00', time: '05:00:00'
        },
        {
            display: '06.00', time: '06:00:00'
        },
        {
            display: '07.00', time: '07:00:00'
        },
        {
            display: '08.00', time: '08:00:00'
        },
        {
            display: '09.00', time: '09:00:00'
        },
        {
            display: '10.00', time: '10:00:00'
        },
        {
            display: '11.00', time: '11:00:00'
        },
        {
            display: '12.00', time: '12:00:00'
        },
        {
            display: '13.00', time: '13:00:00'
        },
        {
            display: '14.00', time: '14:00:00'
        },
        {
            display: '15.00', time: '15:00:00'
        },
        {
            display: '16.00', time: '16:00:00'
        },
        {
            display: '17.00', time: '17:00:00'
        },
        {
            display: '18.00', time: '18:00:00'
        },
        {
            display: '19.00', time: '19:00:00'
        },
        {
            display: '20.00', time: '20:00:00'
        },
        {
            display: '21.00', time: '21:00:00'
        },
        {
            display: '22.00', time: '22:00:00'
        },
        {
            display: '23.00', time: '23:00:00'
        },
        {
            display: '00.00', time: '24:00:00'
        },
        
    ]
}
